export const environment = {
  production: false,
  feUrl: 'https://social.stage.madsense.io',
  // feUrl: 'http://localhost:4200', // Used for LinkedIn
  // serviceUrl: 'http://localhost:8080/api',
  serviceUrl: 'https://social-api.stage.madsense.io/api',
  metaConfigId: '1220257238941913',
  metaInstagramConfigId: '792833459422160',
  metaAppId: '382794467576892',
  client_id: '781k0u4pah1tr5', // Used for LinkedIn
  redirect_uri:
    'https://social-api.stage.madsense.io/api/social-media-connections/linkedin', // Replace with your actual redirect URI, Used for LinkedIn
  scope: 'w_member_social%2copenid%2cemail%2cprofile', // Define the scope of the access you are requesting, Used for LinkedIn
  sentryDsn: 'https://4f570a259cf3af55b75eea3b9068dbeb@o4507844150427648.ingest.us.sentry.io/4507866009829376'
};
